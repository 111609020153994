






























































































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItems } from '@/store/main/actions';
import { IUserItem } from '@/interfaces';

@Component
export default class Dashboard extends Vue {
  public headers = [
    { text: '起始時間', sortable: true, value: 'time_started', align: 'left',},
    { text: '編號', sortable: true, value: 'hashed_id', align: 'left',},
    { text: '方案', sortable: true, value: 'title', align: 'left',},
    { text: '約期', sortable: true, value: 'duration', align: 'left',},
    { text: '剩餘天數', sortable: true, value: 'remain_duration', align: 'left',},
    { text: '起始資產', sortable: true, value: 'initial_value', align: 'left',},
    { text: '現在資產', sortable: true, value: 'current_value', align: 'left',},
    { text: '方案收益', sortable: true, value: 'profit_plan', align: 'left',},
    { text: '獎勵收益', sortable: true, value: 'profit_invite', align: 'left',},
    { text: '績效費減免', sortable: true, value: 'profit_discount', align: 'left',},
    { text: '年化利率', sortable: true, value: 'apr', align: 'left',},
    { text: '年報酬率', sortable: true, value: 'apy', align: 'left',},
    { text: '狀態', sortable: true, value: 'status', align: 'left',},
  ]


  // get greetedUser() {
  //   const userProfile = readUserProfile(this.$store);
  //   if (userProfile) {
  //     if (userProfile.full_name) {
  //       return userProfile.full_name;
  //     } else {
  //       return userProfile.email;
  //     }
  //   }
  // }

  // public switchUSDT = false;
  // public tokenType = 'TWD';
  // public switchDisplay() {
  //   if (this.switchUSDT === true) {
  //     this.tokenType = 'USDT';
  //     return '以 USDT 計算收益與 APR';
  //   } else {
  //     this.tokenType = '新臺幣';
  //     return '以 新臺幣 計算收益與 APR';
  //   }
  // }

  // my edit
  // public headers = [
  //   { text: '起始時間', sortable: true, value: 'startingTime', align: 'left'},
  //   { text: '方案', sortable: true, value: 'title', align: 'left'},
  //   { text: '起始資產', sortable: true, value: 'initialValues', align: 'left'},
  //   { text: '現在資產', sortable: true, value: 'currentValues', align: 'left'},
  //   // { text: '收益', sortable: true, value: 'gains', align: 'left'},
  //   { text: '年化利率', sortable: true, value: 'apr', align: 'left'},
  //   { text: '年化報酬率', sortable: true, value: 'apy', align: 'left'},
  //   { text: '方案收益', sortable: true, value: 'profit_plan', align: 'left'},
  //   { text: '獎勵收益', sortable: true, value: 'profit_invite', align: 'left'},
  //   { text: '狀態', sortable: true, value: 'status', align: 'left'},
  //   { text: '約期', sortable: true, value: 'duration', align: 'left'},
  // ];

  private getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }
  
  get userItems() {
    return readUserItems(this.$store).filter(i => i.status !== 'removed').sort((a: IUserItem, b: IUserItem) => {
        return this.getTime(a.time_started) - this.getTime(b.time_started);
    });
  }

  public async mounted() {
    await dispatchGetUserItems(this.$store);
  }
}
