var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"color":"surface"}},[_c('v-container',{staticClass:"pt-8 pb-10"},[_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"text-h3"},[_vm._v("詳細成效")])]),_c('v-data-table',{staticClass:"mt-6 pa-2",attrs:{"headers":_vm.headers,"items":_vm.userItems,"footer-props":{'items-per-page-options': [-1]}},scopedSlots:_vm._u([{key:"item.time_started",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseTimestamp")(item.time_started))+" ")]}},{key:"item.hashed_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hashed_id)+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseTitle")(item.title))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration)+" ")]}},{key:"item.remain_duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseRemainingDuration")(item.duration,item.time_ended))+" ")]}},{key:"item.initial_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.initial_value,item.token_type))+" "+_vm._s(item.token_type)+" ")]}},{key:"item.current_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.current_value,item.token_type))+" "+_vm._s(item.token_type)+" ")]}},{key:"item.profit_plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("gains")(item.profit_plan,item.token_type))+" "+_vm._s(item.token_type)+" ")]}},{key:"item.profit_invite",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("gains")(item.profit_invite,item.token_type))+" "+_vm._s(item.token_type)+" ")]}},{key:"item.profit_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("gains")(item.profit_discount,item.token_type))+" "+_vm._s(item.token_type)+" ")]}},{key:"item.apr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toPercentage")(item.apr,2))+" ")]}},{key:"item.apy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toPercentage")(item.apy,2))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseStatus")(item.status))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }